
import { defineComponent, reactive, onMounted, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import { ethitransService } from "@/services/EthitransService";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";

// // import MembersCard from "@/components/cards/MembersCard.vue";
// import TemplateCard from "@/components/cards/TemplateCard.vue";
// // import ChannelCard from "@/components/cards/ChannelCard.vue";
// // import ChannelCardTest from "@/components/cards/ChannelCardTest.vue";
// import HistoryCard from "@/components/cards/HistoryCard.vue";
// import EmptyData from "@/components/commonDesign/EmptyData.vue";
import ToDoDetailsAttachment from "@/components/todo/ToDoDetailsAttachment.vue";
import ToDoMembersListForTaskTable from "@/components/todo/ToDoMembersListForTaskTable.vue";
import CheckAvaibilityByUser from "@/components/todo/CheckAvaibilityByUser.vue";

// import { validationService } from "@/common/ValidationService";
// import ToDoPhaseStatus from "@/components/todo/ToDoPhaseStatus.vue";
import { common } from "@/common/services/Common";
import { config } from "@/Config";
import eventBus from "@/eventBus";

export default defineComponent({
  name: "PhaseInvitationAcknowledgement",
  components: {
    ToDoDetailsAttachment,
    ToDoMembersListForTaskTable,
    // TemplateCard,
    SuccessfulModalCustom,
    // ToDoPhaseStatus,
    CheckAvaibilityByUser,
    // HistoryCard,
    // EmptyData,
    ErrorList,
  },
  setup() {
    // const store = useStore();
    const router = useRouter();
    const {
      params: { invitationId },
    } = useRoute();
    // console.log("CHECK ID, >>", projectId);
    const state = reactive({
      data: {
        projectId: "" as any,
        showAvailibilityModal: false,
        selectAll: false,
        showSuccesCustomModal: false,
        activeUserEmail: "" as any,
        successFulMessage: "",
        selectedInvition: {} as any,
        selectedUserSlotDetails: {
          slots: [] as any,
        } as any,
        acknowledgementDetails: {
          attachments: [],
          breadcrumbs: [],
          payers: [],
          phase: {} as any,
          project: {},
          estimation: {
            amount: 0,
            type: '',
          },
          type: 0,
          role: 0,
          description: "",
          todayLog: {} as any,
        } as any,
        channelIndex: 0,
        inviteAsGuest: false,
      },
      ui: {
        modal: {
          showProjectAcknowledgementModal: false,
        },
        isLoading: {
          phaseAcknowledgement: false,
        },
        isPostLoading: {
          redirectToChat: false,
          acknowledgeProject: false,
          declineProject: false,
          phaseAcknowledgement: false,
          acceptInvitation: false,
          acceptSelectedPhase: false,
          declineSelectedPhase: false,
        },
        errors: {
          errorList: {
            phaseAcknowledgement: [] as any,
            declineTaskInvitations: [] as any,
          },
          hasError: false,
        },
      },
    });
    const isInvitatationSelected = computed(() => {
      if (!state.data.acknowledgementDetails.child_invitations) return false;
      const res = state.data.acknowledgementDetails.child_invitations.some(
        (item: any) => item.phase_detail.phase.isChecked === true
      );
      return res;
    });
    watch(
      // () => watchTour,
      () => state.data.selectAll,
      (newvalue, oldvalue) => {
        if (newvalue === true) {
          // console.log("TRUE");
          state.data.acknowledgementDetails.child_invitations.forEach(
            (item: any) => {
              // if (item.status === "Close" && item.invoice === null) {
              item.phase_detail.phase.isChecked = true;
              // emit("selectedTasks", item);
              // } else {
              //   item.isChecked = false;
              // }
            }
          );
        } else {
          // console.log("FALSE");
          state.data.acknowledgementDetails.child_invitations.forEach(
            (item: any) => {
              item.phase_detail.phase.isChecked = false;
            }
          );
        }
        // const res = state.data.acknowledgementDetails.child_invitations.some(
        //   (item: any) => item.phase_detail.phase.isChecked === true
        // );
        // console.log(res);
        // emit(
        //   "checkedTask",
        //   state.data.acknowledgementDetails.child_invitations.filter((e: any) => e.isChecked)
        // );
      }
    );

    function redirectFromInvitation() {
      $("#redirectFromInvitation").modal("show");
    }
    function closeSuccessCustomModal() {
      if (state.data.acknowledgementDetails.type === 6) {
        redirectFromInvitation();
        // state.data.successFulMessage = "Phase Joined";
      } else if (state.data.acknowledgementDetails.type === 10) {
        // state.data.successFulMessage = "Task Joined";
        redirectFromInvitation();
      }
    }
    function hideRedirectFromInvitation() {
      $("#redirectFromInvitation").modal("hide");
    }
    function hideAvaibilityModal() {
      state.data.showAvailibilityModal = false;
    }
    function showCalender() {
      state.data.showAvailibilityModal = true;
    }
    function showGenerateConfirmationModal() {
      state.ui.errors.errorList.phaseAcknowledgement = [];
      state.ui.errors.hasError = false;
      $("#generateConfirmationModal").modal("show");
    }
    function hideGenerateConfirmationModal() {
      state.ui.errors.errorList.phaseAcknowledgement = [];
      state.ui.errors.hasError = false;
      $("#generateConfirmationModal").modal("hide");
    }
    function check(selectedData: any) {
      // const res = state.data.acknowledgementDetails.child_invitations.some(
      //     (item: any) => item.phase_detail.phase.isChecked === true
      //   );
      //   console.log(res);
    }
    function redirectToDoc() {
      window.open(state.data.acknowledgementDetails.phase.edoc_id, "_blank");
    }
    function redirectToChat() {
      // alert("Redirect to Chat Room");
      // payerDiscussionChannel
      state.ui.errors.errorList.phaseAcknowledgement = [];
      state.ui.errors.hasError = false;
      if (state.ui.isPostLoading.redirectToChat) return false;
      state.ui.isPostLoading.redirectToChat = true;
      ethitransService
        .phaseApprovalChannel(invitationId.toString())
        .then((res: any) => {
          router.push({
            name: "admin-channel",
            params: { channelId: res.data.data.channel.uuid.toString() },
          });
          console.log(
            "Acknowledge Project Response:>>> ",
            res.data.data.channel
          );
          // res.data.data.payerRequest.localStartedDate = common.localDateAndTime(
          //   res.data.data.payerRequest.start_date
          // );
          // res.data.data.payerRequest.localEndDate = common.localDateAndTime(
          //   res.data.data.payerRequest.end_date
          // );
          // res.data.data.attachments.forEach((attachment: any) => {
          //   // attachment.file_size
          //   attachment.convertedFileSize = common.formatBytes(
          //     parseInt(attachment.file_size)
          //   );
          // });
          // state.data.acknowledgementDetails = res.data.data;
          // state.data.acknowledgementDetails.payerRequest.status = 2;
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.phaseAcknowledgement)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.phaseAcknowledgement.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.redirectToChat = false;
        });
    }
    function redirectPhaseInvitationChat() {
      // alert("Redirect to Chat Room");
      // payerDiscussionChannel
      state.ui.errors.errorList.phaseAcknowledgement = [];
      state.ui.errors.hasError = false;
      if (state.ui.isPostLoading.redirectToChat) return false;
      state.ui.isPostLoading.redirectToChat = true;
      ethitransService
        .phaseInvitationChannel(invitationId.toString())
        .then((res: any) => {
          router.push({
            name: "admin-channel",
            params: { channelId: res.data.data.channel.uuid.toString() },
          });
          console.log(
            "Acknowledge Project Response:>>> ",
            res.data.data.channel
          );
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.phaseAcknowledgement)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.phaseAcknowledgement.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.redirectToChat = false;
        });
    }
    function chatRedirect() {
      if (
        state.data.acknowledgementDetails.phase_detail.role === 1 ||
        state.data.acknowledgementDetails.phase_detail.role === 4
      ) {
        redirectToChat();
      }
      if (state.data.acknowledgementDetails.phase_detail.role === 3) {
        redirectPhaseInvitationChat();
      }
    }
    function acknowledgeProject() {
      state.ui.errors.errorList.phaseAcknowledgement = [];
      state.ui.errors.hasError = false;
      if (state.ui.isPostLoading.acknowledgeProject) return false;
      state.ui.isPostLoading.acknowledgeProject = true;
      ethitransService
        .acceptProjectApprovalInvitation(invitationId.toString())
        .then((res: any) => {
          // console.log("Acknowledge Project Response:>>> ", res.data.data);
          // res.data.data.payerRequest.localStartedDate = common.localDateAndTime(
          //   res.data.data.payerRequest.start_date
          // );
          // res.data.data.payerRequest.localEndDate = common.localDateAndTime(
          //   res.data.data.payerRequest.end_date
          // );
          // res.data.data.attachments.forEach((attachment: any) => {
          //   // attachment.file_size
          //   attachment.convertedFileSize = common.formatBytes(
          //     parseInt(attachment.file_size)
          //   );
          // });
          // state.data.acknowledgementDetails = res.data.data;
          state.data.acknowledgementDetails.phase.status = 2;
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.phaseAcknowledgement)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.phaseAcknowledgement.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.acknowledgeProject = false;
        });
      // alert("Acknowledge Project");
    }
    function closeAcknowledgementInvitationModal() {
      $("#deleteAcknowledgementInvitationModal").modal("hide");
    }
    function declineProject() {
      state.ui.errors.errorList.phaseAcknowledgement = [];
      state.ui.errors.hasError = false;
      if (state.ui.isPostLoading.declineProject) return false;
      if (!state.data.acknowledgementDetails.description) return false;
      const payLoad = {
        id: invitationId.toString(),
        description: state.data.acknowledgementDetails.description,
      };
      state.ui.isPostLoading.declineProject = true;
      ethitransService
        .declineProjectApprovalInvitation(payLoad)
        .then((res: any) => {
          // console.log("Decline Project Response:>>> ", res.data.data);
          // res.data.data.payerRequest.localStartedDate = common.localDateAndTime(
          //   res.data.data.payerRequest.start_date
          // );
          // res.data.data.payerRequest.localEndDate = common.localDateAndTime(
          //   res.data.data.payerRequest.end_date
          // );
          // res.data.data.attachments.forEach((attachment: any) => {
          //   // attachment.file_size
          //   attachment.convertedFileSize = common.formatBytes(
          //     parseInt(attachment.file_size)
          //   );
          // });
          // // res.data.data.payerRequest.status = 3;
          // state.data.acknowledgementDetails = res.data.data;
          state.data.acknowledgementDetails.phase.status = 3;
          closeAcknowledgementInvitationModal();
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.phaseAcknowledgement)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.phaseAcknowledgement.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.declineProject = false;
        });
    }

    function showSelectedDeclineModal() {
      state.data.acknowledgementDetails.description = "";
      state.ui.errors.errorList.declineTaskInvitations = [];
      state.ui.errors.hasError = false;
      $("#deleteSelectedInvitation").modal("show");
    }

    function hideSelectedDeclineModal() {
      state.ui.errors.errorList.declineTaskInvitations = [];
      state.ui.errors.hasError = false;
      state.data.acknowledgementDetails.description = "";
      $("#deleteSelectedInvitation").modal("hide");
    }
    function showDeclineModal() {
      state.data.acknowledgementDetails.description = "";
      $("#deleteAcknowledgementInvitationModal").modal("show");
    }
    function phaseAcknowledgement() {
      state.ui.errors.errorList.phaseAcknowledgement = [];
      state.ui.errors.hasError = false;
      if (state.ui.isLoading.phaseAcknowledgement) return false;
      state.ui.isLoading.phaseAcknowledgement = true;
      ethitransService
        .phaseInvitation(invitationId.toString())
        .then((res: any) => {
          state.data.projectId = res.data.data.phase_detail.project.uuid
          if (res.data.data.phase.task_updates && res.data.data.phase.task_updates.length) {
            res.data.data.phase.task_updates.forEach((update: any) => {
              update.localUpdatedAt = common.localDateOnly(update.updated_at)

            });
          }
          console.log(
            "Project AcknowledgementDetails Response:>>> ",
            res.data.data
          );
          res.data.data.phase.localStartedDate = common.localDateOnly(
            res.data.data.phase.start_date
          );
          res.data.data.phase.localEndDate = common.localDateOnly(
            res.data.data.phase.end_date
          );
          if (res.data.data.time_slots) {
            res.data.data.time_slots.forEach((slot: any) => {
              slot.localStartedDate = common.formatDateAndTimeSlot(
                slot.start_time
              );
              slot.localEndDate = common.formatDateAndTimeSlot(slot.end_time);
            });
            state.data.selectedUserSlotDetails.slots = res.data.data.time_slots;
          }
          res.data.data.child_invitations.forEach((invitation: any) => {
            // invitation.phase_detail.phase.start_date
            invitation.phase_detail.phase.isChecked = false;
            invitation.phase_detail.phase.localStartedDate =
              common.localDateOnly(invitation.phase_detail.phase.start_date);
            invitation.phase_detail.phase.localEndDate = common.localDateOnly(
              invitation.phase_detail.phase.end_date
            );
          });

          if (res.data.data.attachments && res.data.data.attachments.length) {
            res.data.data.attachments.forEach((attachment: any) => {
              attachment.activeLink =
                config.liveImageBaseUrl + attachment.file_link;
              // console.log("CHECK THE ACTIVE LINK >>> ", attachment );
              // attachment.file_size
              attachment.convertedFileSize = common.formatBytes(
                parseInt(attachment.file_size)
              );
            });
          }
          if (
            res.data.data.phase_detail &&
            res.data.data.phase_detail.invitations &&
            res.data.data.phase_detail.invitations.length
          ) {
            res.data.data.phase_detail.invitations =
              res.data.data.phase_detail.invitations.filter(
                (member: any) => member.type == 6 || 10
              );
          }
          if (res.data.data.type === 7) {
            res.data.data.showGeneral = true;
          } else {
            res.data.data.showGeneral = false;
          }
          state.data.acknowledgementDetails = res.data.data;
          // redirectFromInvitation();
          state.ui.isLoading.phaseAcknowledgement = false;
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.phaseAcknowledgement)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.phaseAcknowledgement.push(value[0]);
                }
              }
            });
        });
    }

    function acceptInvitation(uuid: any) {
      state.ui.errors.errorList.phaseAcknowledgement = [];
      state.ui.errors.hasError = false;
      // console.log("OHH ACCEPT");
      if (state.ui.isPostLoading.acceptInvitation) return false;
      const payLoad = {
        uuid: uuid,
        // uuid: state.uuId
      };
      state.ui.isPostLoading.acceptInvitation = true;

      ethitransService
        .joinInvitation(payLoad)
        .then((res: any) => {
          // eslint-disable-next-line @typescript-eslint/camelcase
          state.data.acknowledgementDetails.is_complete = 1;
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = "Channel Joined";
          // console.log("CHECK acceptInvitation:>>", res.data.data);
          // removeSelectedInvitation();
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.phaseAcknowledgement)
            .then((res: any) => {
              state.ui.errors.hasError = true;

              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.phaseAcknowledgement.push(value[0]);
                }
              }
              // console.log("CHECK for Error >>>", res)
              // if (res) {
              //   if (res.error) {
              //     console.log("CHECK ERROR", res.error)
              //     state.ui.errors.errorList.phaseAcknowledgement = res.error;
              //     // state.ui.errors.errorList.phaseAcknowledgement = [...state.ui.errors.errorList.phaseAcknowledgement]
              //   } else {
              //     // state.ui.errors.errorList.phaseAcknowledgement.push(res);
              //   }
              // }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.acceptInvitation = false;
        });
    }
    function acceptTodoInvitation(uuid: any) {
      state.ui.errors.errorList.phaseAcknowledgement = [];
      state.ui.errors.hasError = false;
      // console.log("OHH ACCEPT");
      if (state.ui.isPostLoading.acceptInvitation) return false;
      state.ui.isPostLoading.acceptInvitation = true;

      ethitransService
        .acceptToDoInvitation(uuid.toString())
        .then((res: any) => {
          // eslint-disable-next-line @typescript-eslint/camelcase
          state.data.acknowledgementDetails.is_complete = 1;
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = "Channel Joined";
          // console.log("CHECK acceptInvitation:>>", res.data.data);
          // removeSelectedInvitation();
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.phaseAcknowledgement)
            .then((res: any) => {
              state.ui.errors.hasError = true;

              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.phaseAcknowledgement.push(value[0]);
                }
              }
              // console.log("CHECK for Error >>>", res)
              // if (res) {
              //   if (res.error) {
              //     console.log("CHECK ERROR", res.error)
              //     state.ui.errors.errorList.phaseAcknowledgement = res.error;
              //     // state.ui.errors.errorList.phaseAcknowledgement = [...state.ui.errors.errorList.phaseAcknowledgement]
              //   } else {
              //     // state.ui.errors.errorList.phaseAcknowledgement.push(res);
              //   }
              // }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.acceptInvitation = false;
        });
    }
    function acceptPhaseInvitation(uuid: any) {
      state.ui.errors.errorList.phaseAcknowledgement = [];
      state.ui.errors.hasError = false;
      // console.log("OHH ACCEPT");
      if (state.ui.isPostLoading.acceptInvitation) return false;
      state.ui.isPostLoading.acceptInvitation = true;

      ethitransService
        .acceptPhaseInvitation(uuid.toString())
        .then((res: any) => {
          state.ui.isPostLoading.acceptInvitation = false;
          // eslint-disable-next-line @typescript-eslint/camelcase
          state.data.acknowledgementDetails.is_complete = 1;
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          if (state.data.acknowledgementDetails.type === 6) {
            state.data.successFulMessage = "Phase Joined";
          } else if (state.data.acknowledgementDetails.type === 10) {
            state.data.successFulMessage = "Task Joined";
          } else {
            state.data.successFulMessage = "Channel Joined";
          }
          // console.log("CHECK acceptInvitation:>>", res.data.data);
          // removeSelectedInvitation();
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.phaseAcknowledgement)
            .then((res: any) => {
              state.ui.errors.hasError = true;

              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.phaseAcknowledgement.push(value[0]);
                }
              }
              // console.log("CHECK for Error >>>", res)
              // if (res) {
              //   if (res.error) {
              //     console.log("CHECK ERROR", res.error)
              //     state.ui.errors.errorList.phaseAcknowledgement = res.error;
              //     // state.ui.errors.errorList.phaseAcknowledgement = [...state.ui.errors.errorList.phaseAcknowledgement]
              //   } else {
              //     // state.ui.errors.errorList.phaseAcknowledgement.push(res);
              //   }
              // }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.acceptInvitation = false;
        });
    }
    function join() {
      if (state.data.acknowledgementDetails.type === 1) {
        // normal invitation
        // console.log("SELECTED >>> ", state.data.acknowledgementDetails);
        console.log("INVIT ID >>> ", invitationId);
        console.log("TYPE CHECK >>> ", state.data.acknowledgementDetails.type);
        acceptInvitation(invitationId);
        // alert("ACCEPT INVITATION");
      } else if (state.data.acknowledgementDetails.type === 2) {
        // todo invitation
        // console.log("SELECTED >>> ", state.data.acknowledgementDetails);
        console.log("INVIT ID >>> ", invitationId);
        console.log("TYPE CHECK >>> ", state.data.acknowledgementDetails.type);
        acceptTodoInvitation(invitationId);
        // alert("ACCEPT TODO INVITATION");
      } else if (
        state.data.acknowledgementDetails.type === 3 ||
        state.data.acknowledgementDetails.type === 5 ||
        state.data.acknowledgementDetails.type === 7 ||
        state.data.acknowledgementDetails.type === 6
      ) {
        // invitation
        // console.log("SELECTED >>> ", state.data.acknowledgementDetails);
        console.log("INVIT ID >>> ", invitationId);
        console.log("TYPE CHECK >>> ", state.data.acknowledgementDetails.type);
        // alert("ACCEPT ACCEPT PHASE INVITATION");
        acceptPhaseInvitation(invitationId);
      }
    }
    function declineInvitation(uuid: any) {
      state.ui.errors.errorList.phaseAcknowledgement = [];
      state.ui.errors.hasError = false;
      if (state.ui.isPostLoading.declineProject) return false;
      state.ui.isPostLoading.declineProject = true;
      const payLoad = {
        uuid: uuid,
        // uuid: state.uuId
      };
      ethitransService
        .declineInvitation(payLoad)
        .then((res: any) => {
          // eslint-disable-next-line @typescript-eslint/camelcase
          state.data.acknowledgementDetails.is_declined = 1;
          // console.log("CHECK declineInvitation:>>", res.data);
          closeAcknowledgementInvitationModal();
        })
        .catch((error: any) => {
          console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.phaseAcknowledgement)
            .then((res: any) => {
              state.ui.errors.hasError = true;

              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.phaseAcknowledgement.push(value[0]);
                }
              }
              // console.log("CHECK for Error >>>", res)
              // if (res) {
              //   if (res.error) {
              //     console.log("CHECK ERROR", res.error)
              //     state.ui.errors.errorList.phaseAcknowledgement = res.error;
              //     // state.ui.errors.errorList.phaseAcknowledgement = [...state.ui.errors.errorList.phaseAcknowledgement]
              //   } else {
              //     // state.ui.errors.errorList.phaseAcknowledgement.push(res);
              //   }
              // }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.declineProject = false;
        });
    }
    function declinePhaseInvitation(uuid: any) {
      state.ui.errors.errorList.phaseAcknowledgement = [];
      state.ui.errors.hasError = false;
      if (state.ui.isPostLoading.declineProject) return false;
      if (!state.data.acknowledgementDetails.description) return false;
      const payLoad = {
        id: uuid.toString(),
        description: state.data.acknowledgementDetails.description,
      };
      state.ui.isPostLoading.declineProject = true;
      console.log("PAYLOAD CHECK >>> ", payLoad);
      ethitransService
        .declinePhaseInvitation(payLoad)
        .then((res: any) => {
          // eslint-disable-next-line @typescript-eslint/camelcase
          state.data.acknowledgementDetails.is_declined = 1;
          // console.log("CHECK declineInvitation:>>", res.data);
          // state.pendingList = res.data ? res.data : [];
          // removeSelectedInvitation();
          closeAcknowledgementInvitationModal();
        })
        .catch((error: any) => {
          console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.phaseAcknowledgement)
            .then((res: any) => {
              state.ui.errors.hasError = true;

              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.phaseAcknowledgement.push(value[0]);
                }
              }
              // console.log("CHECK for Error >>>", res)
              // if (res) {
              //   if (res.error) {
              //     console.log("CHECK ERROR", res.error)
              //     state.ui.errors.errorList.phaseAcknowledgement = res.error;
              //     // state.ui.errors.errorList.phaseAcknowledgement = [...state.ui.errors.errorList.phaseAcknowledgement]
              //   } else {
              //     // state.ui.errors.errorList.phaseAcknowledgement.push(res);
              //   }
              // }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.declineProject = false;
        });
    }
    function declineToDoInvitation(uuid: any) {
      state.ui.errors.errorList.phaseAcknowledgement = [];
      state.ui.errors.hasError = false;
      if (state.ui.isPostLoading.declineProject) return false;
      state.ui.isPostLoading.declineProject = true;
      ethitransService
        .declineToDoInvitation(uuid.toString())
        .then((res: any) => {
          // eslint-disable-next-line @typescript-eslint/camelcase
          state.data.acknowledgementDetails.is_declined = 1;
          // console.log("CHECK declineInvitation:>>", res.data);
          closeAcknowledgementInvitationModal();
        })
        .catch((error: any) => {
          console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.phaseAcknowledgement)
            .then((res: any) => {
              state.ui.errors.hasError = true;

              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.phaseAcknowledgement.push(value[0]);
                }
              }
              // console.log("CHECK for Error >>>", res)
              // if (res) {
              //   if (res.error) {
              //     console.log("CHECK ERROR", res.error)
              //     state.ui.errors.errorList.phaseAcknowledgement = res.error;
              //     // state.ui.errors.errorList.phaseAcknowledgement = [...state.ui.errors.errorList.phaseAcknowledgement]
              //   } else {
              //     // state.ui.errors.errorList.phaseAcknowledgement.push(res);
              //   }
              // }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.declineProject = false;
        });
    }
    function decline() {
      // state.data.selectedInvition = selectedInvition;
      if (state.data.acknowledgementDetails.type === 1) {
        // normal invitation
        declineInvitation(invitationId);
      } else if (state.data.acknowledgementDetails.type === 2) {
        // dont comes in after
        // todo invitation decline
        console.log("SELECTED DECLINE>>> ", state.data.selectedInvition);
        declineToDoInvitation(invitationId);
      } else if (
        state.data.acknowledgementDetails.type === 3 ||
        state.data.acknowledgementDetails.type === 5 ||
        state.data.acknowledgementDetails.type === 7 ||
        state.data.acknowledgementDetails.type === 6
      ) {
        // phase invitation decline
        declinePhaseInvitation(invitationId);
        console.log("SELECTED DECLINE>>> ", state.data.selectedInvition);
      }
      console.log("SELECTED DECLINE>>> ", state.data.selectedInvition);
    }

    function acceptSelectedPhase() {
      state.ui.errors.errorList.phaseAcknowledgement = [];
      state.ui.errors.hasError = false;
      // console.log("OHH ACCEPT");
      const invitations: any = [];
      state.data.acknowledgementDetails.child_invitations.forEach(
        (item: any) => {
          if (item.phase_detail.phase.isChecked) {
            invitations.push(item.uuid);
          }
        }
      );
      if (state.ui.isPostLoading.acceptSelectedPhase) return false;
      const payLoad = {
        id: invitations,
      };
      state.ui.isPostLoading.acceptSelectedPhase = true;
      console.log("CHECK THE PAYLOAD >>> ", payLoad);
      state.ui.isPostLoading.acceptSelectedPhase = false;
      ethitransService
        .acceptSelectedInvitation(payLoad)
        .then((res: any) => {
          state.ui.errors.errorList.phaseAcknowledgement = [];
          state.ui.errors.hasError = false;
          $("#generateConfirmationModal").modal("hide");

          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = "Task Joined";
          state.ui.isPostLoading.acceptSelectedPhase = false;

          console.log("Check data >>> ", res.data.data)

          state.data.acknowledgementDetails.child_invitations.forEach((invitation: any) => {
            // invitation.phase_detail.phase.start_date
            invitation.phase_detail.phase.isChecked = false;
            invitation.phase_detail.phase.localStartedDate =
              common.localDateOnly(invitation.phase_detail.phase.start_date);
            invitation.phase_detail.phase.localEndDate = common.localDateOnly(
              invitation.phase_detail.phase.end_date
            );
          });
          const childInvitaions = state.data.acknowledgementDetails.child_invitations;

          if (state.data.acknowledgementDetails.attachments && state.data.acknowledgementDetails.attachments.length) {
            state.data.acknowledgementDetails.attachments.forEach((attachment: any) => {
              attachment.activeLink =
                config.liveImageBaseUrl + attachment.file_link;
              // console.log("CHECK THE ACTIVE LINK >>> ", attachment );
              // attachment.file_size
              attachment.convertedFileSize = common.formatBytes(
                parseInt(attachment.file_size)
              );
            });
          }

          state.data.acknowledgementDetails.type = 6;


          // res.data.data.child_invitations.forEach((invitation: any) => {
          //   // invitation.phase_detail.phase.start_date
          //   invitation.phase_detail.phase.isChecked = false;
          //   invitation.phase_detail.phase.localStartedDate =
          //     common.localDateOnly(invitation.phase_detail.phase.start_date);
          //   invitation.phase_detail.phase.localEndDate = common.localDateOnly(
          //     invitation.phase_detail.phase.end_date
          //   );
          // });


          // if (res.data.data.attachments && res.data.data.attachments.length) {
          //   res.data.data.attachments.forEach((attachment: any) => {
          //     attachment.activeLink =
          //       config.liveImageBaseUrl + attachment.file_link;
          //     // console.log("CHECK THE ACTIVE LINK >>> ", attachment );
          //     // attachment.file_size
          //     attachment.convertedFileSize = common.formatBytes(
          //       parseInt(attachment.file_size)
          //     );
          //   });
          // }
          if (res.data.data.type === 7 || res.data.data.type === 6 || res.data.data.type === 10) {
            res.data.data.showGeneral = true;
          } else {
            res.data.data.showGeneral = false;
          }
          res.data.data.phase.localStartedDate = common.localDateOnly(
            res.data.data.phase.start_date
          );
          res.data.data.phase.localEndDate = common.localDateOnly(
            res.data.data.phase.end_date
          );
          // eslint-disable-next-line @typescript-eslint/camelcase
          res.data.data.child_invitations = childInvitaions;
          res.data.data.type = 6;
          state.data.acknowledgementDetails = res.data.data;
          hideGenerateConfirmationModal();

          // console.log("CHECK acceptSelectedPhase:>>", res.data.data);
          // removeSelectedInvitation();

        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.phaseAcknowledgement)
            .then((res: any) => {
              state.ui.errors.hasError = true;

              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.phaseAcknowledgement.push(value[0]);
                }
              }
            });
        })
    }
    function declineSelectedPhase() {
      state.ui.errors.errorList.declineTaskInvitations = [];
      state.ui.errors.hasError = false;
      // console.log("OHH ACCEPT");
      const invitations: any = [];
      state.data.acknowledgementDetails.child_invitations.forEach(
        (item: any) => {
          if (item.phase_detail.phase.isChecked) {
            invitations.push(item.uuid);
          }
        }
      );
      if (state.ui.isPostLoading.declineSelectedPhase) return false;
      if (!state.data.acknowledgementDetails.description) return false;
      const payLoad = {
        id: invitations,
        description: state.data.acknowledgementDetails.description,
      };
      console.log("CHECK THE DECLINE PAYLOAD >>> ", payLoad);
      state.ui.isPostLoading.declineSelectedPhase = true;

      ethitransService
        .declineSelectedInvitation(payLoad)
        .then((res: any) => {
          res.data.data.child_invitations.forEach((invitation: any) => {
            // invitation.phase_detail.phase.start_date
            invitation.phase_detail.phase.isChecked = false;
            invitation.phase_detail.phase.localStartedDate =
              common.localDateOnly(invitation.phase_detail.phase.start_date);
            invitation.phase_detail.phase.localEndDate = common.localDateOnly(
              invitation.phase_detail.phase.end_date
            );
          });

          if (res.data.data.attachments && res.data.data.attachments.length) {
            res.data.data.attachments.forEach((attachment: any) => {
              attachment.activeLink =
                config.liveImageBaseUrl + attachment.file_link;
              // console.log("CHECK THE ACTIVE LINK >>> ", attachment );
              // attachment.file_size
              attachment.convertedFileSize = common.formatBytes(
                parseInt(attachment.file_size)
              );
            });
          }
          // res.data.data.phase_detail.invitations =
          //   res.data.data.phase_detail.invitations.filter(
          //     (member: any) => member.type == 6
          //   );
          if (res.data.data.type === 7 || res.data.data.type === 6) {
            res.data.data.showGeneral = true;
          } else {
            res.data.data.showGeneral = false;
          }
          state.data.acknowledgementDetails = res.data.data;

          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = "Task Declined";
          // console.log("CHECK declineSelectedPhase:>>", res.data.data);
          // removeSelectedInvitation();
          state.ui.isPostLoading.declineSelectedPhase = false;
          hideSelectedDeclineModal();
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.declineTaskInvitations)
            .then((res: any) => {
              state.ui.errors.hasError = true;

              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.declineTaskInvitations.push(
                    value[0]
                  );
                }
              }
              // console.log("CHECK for Error >>>", res)
              // if (res) {
              //   if (res.error) {
              //     console.log("CHECK ERROR", res.error)
              //     state.ui.errors.errorList.declineTaskInvitations = res.error;
              //     // state.ui.errors.errorList.declineTaskInvitations = [...state.ui.errors.errorList.declineTaskInvitations]
              //   } else {
              //     // state.ui.errors.errorList.declineTaskInvitations.push(res);
              //   }
              // }
            });
          // hideSelectedDeclineModal();
        });
    }
    function redirectToDashboard() {
      hideRedirectFromInvitation();
      // state.data.acknowledgementDetails
      router.push({
        name: "admin-dashboard",
      });
    }
    // function redirectToPhaseListDashboard() {
    //   hideRedirectFromInvitation();
    //   // state.data.acknowledgementDetails
    //   router.push({
    //     name: "user-todo-list",
    //     params: {
    //       projectId:
    //         state.data.acknowledgementDetails.phase_detail.smart_contract_id,
    //     },
    //   });
    // }
    
    // use for new workflow-design
    function redirectToPhaseListDashboard() {
      hideRedirectFromInvitation();
      // state.data.acknowledgementDetails
      router.push({
        name: "admin-project-details-new",
        params: {
          projectId: state.data.projectId,
          contractId:
            state.data.acknowledgementDetails.phase_detail.smart_contract_id,
        },
        query: { tab: 'toDoList' }
      });
    }
    // function redirectToPhaseDetails() {
    //   hideRedirectFromInvitation();
    //   // state.data.acknowledgementDetails
    //   if (state.data.acknowledgementDetails.phase && state.data.acknowledgementDetails.phase.parent_phase  && state.data.acknowledgementDetails.phase.parent_phase.uuid ) {
    //     router.push({
    //       name: "user-todo-details",
    //       params: { phaseId: state.data.acknowledgementDetails.phase.parent_phase.uuid },
    //     });
    //   } else {
    //     router.push({
    //       name: "user-todo-details",
    //       params: { phaseId: state.data.acknowledgementDetails.phase.uuid },
    //     });
    //   }

    // }

    // use for new workflow-design
    function redirectToPhaseDetails() {
      hideRedirectFromInvitation();
      // state.data.acknowledgementDetails
      if (state.data.acknowledgementDetails.phase && state.data.acknowledgementDetails.phase.parent_phase && state.data.acknowledgementDetails.phase.parent_phase.uuid) {
        router.push({
          name: "admin-project-details-new",
          params: { projectId: state.data.projectId, phaseId: state.data.acknowledgementDetails.phase.parent_phase.uuid },
          query: { tab: 'toDoDetails' }
        });
      } else {
        router.push({
          name: "admin-project-details-new",
          params: { projectId: state.data.projectId, phaseId: state.data.acknowledgementDetails.phase.uuid },
          query: { tab: 'toDoDetails' }
        });
      }

    }
    // function goToTaskDetails(uuid: any) {
    //   hideRedirectFromInvitation();
    //   // state.data.acknowledgementDetails
    //   router.push({
    //     name: "user-todo-details",
    //     params: { phaseId: uuid },
    //   });
    // }

    // use for new workflow-design
    function goToTaskDetails(uuid: any) {
      hideRedirectFromInvitation();
      // state.data.acknowledgementDetails
      router.push({
        // name: "user-todo-details",
        name: "admin-project-details-new",
        params: { projectId: state.data.projectId, phaseId: uuid },
        query: { tab: 'toDoDetails' }
      });
    }
    onMounted(() => {
      console.log("Check>> ", invitationId);
      phaseAcknowledgement();
      state.data.activeUserEmail = localStorage.getItem("current_user_email");
    });

    return {
      state,
      closeSuccessCustomModal,
      redirectToDashboard,
      redirectToPhaseListDashboard,
      redirectToPhaseDetails,
      goToTaskDetails,
      hideRedirectFromInvitation,
      redirectFromInvitation,
      hideAvaibilityModal,
      showCalender,
      showGenerateConfirmationModal,
      hideGenerateConfirmationModal,
      declineSelectedPhase,
      showSelectedDeclineModal,
      hideSelectedDeclineModal,
      join,
      acceptSelectedPhase,
      isInvitatationSelected,
      check,
      chatRedirect,
      redirectPhaseInvitationChat,
      acceptInvitation,
      acceptTodoInvitation,
      acceptPhaseInvitation,
      decline,
      closeAcknowledgementInvitationModal,
      showDeclineModal,
      phaseAcknowledgement,
      redirectToChat,
      acknowledgeProject,
      declineProject,
      redirectToDoc,
    };
  },
});
